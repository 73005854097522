import React from 'react';
import { Layout } from '../../components/shared/Layout';

const OrganisatiePage = (): JSX.Element => {
  return (
    <Layout>
      <main className="">
        <section className="bg-white py-8">
          <div className="container mx-auto max-w-xl">
            <h1>Voor organisaties</h1>
            <p>
              Laat Haagsche Schil zorg dragen voor de verwerking van uw
              organisch afval. Draag bij aan circulair Den Haag en laat zien dat
              uw organisatie ook duurzaam is.
            </p>

            <p>
              Door mee te doen aan Haagsche Schil draagt uw organisatie, bedrijf
              of ondernemersvereniging aan een duurzaam en circulair Den Haag.
            </p>

            <p>
              Laat uw klanten zien dat u ook geeft om een duurzame wereld die
              bijdraagt aan een circulaire stad.
            </p>

            <p>
              In november en december 2017 voeren we de eerste pilot van
              Haagsche Schil uit voor bewoners. Daarna breiden we uit naar
              bedrijven en organisaties.
            </p>
          </div>
        </section>
      </main>
    </Layout>
  );
};

export default OrganisatiePage;
